import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AbstractControlDirective, AbstractControl } from '@angular/forms';

// @dynamic
@Component({
 standalone: true,
 selector: 'app-validation-errors',
 template: `
   <ng-container *ngIf="shouldShowErrors()">
    <small class="text-danger validation-error">{{getError()}}</small>
   </ng-container>
 `,
  imports: [
    CommonModule
 ]
})

export class ValidationErrorsComponent {

  private static readonly errorMessages = {
    'required': (params) => '##FIELD## is required.',
    'min': (params) => 'Minimum allowed value for ##FIELD## is ' + parseInt(params.min, 10),
    'max': (params) => 'Maximum allowed value for ##FIELD## is ' + params.max,
    'minlength': (params) => '##FIELD## should be minimum ' + params.requiredLength + ' characters.',
    'maxlength': (params) => '##FIELD## should not be greater than ' + params.requiredLength + ' characters.',
    'pattern': (params) => '##FIELD## should be a valid',
    'email': (params) => 'Invalid email.',
    'validateNumber': (params) => 'Should be valid number.',
    'validateEmail': (params) => 'Should be vaild email.',
    'owlDateTimeMin': (params) => ' ##FIELD##  is invalid ',
    'owlDateTimeParse': (params) => 'Invalid Date Time value.',
    'noWhitespace': (params) => '##FIELD## should not have whitespace.',
    'validatePricingField': (params) => '##FIELD## should have only number or "+".',
    'invalidPassword': (params) => '##FIELD## requires minimum 5 characters, must include at least 1 number and 1 special character.',
    'invalidAmount': (params) => '##FIELD## allows a maximum of 5 digits before the decimal point and 3 digits after the decimal point.',
    'url': (params) => 'Invalid ##FIELD##',
    'maxQty': (params) =>'Total ##FIELD## available is ' + params.maxQty,
    'incorrectPassword': (params) => 'Current Password is incorrect',
    'sameAsCurrent': (params) => 'New Password cannot be same as Current Password',
    'mismatchedPasswords': (params) => 'Passwords do not match',
  };

  @Input()
  private control: AbstractControlDirective | AbstractControl;

  @Input() submitted: boolean;
  @Input() fieldName: string;

  constructor() {}

  shouldShowErrors(): boolean {
    return this.control && this.control.errors && (this.control.dirty || this.control.touched || this.submitted);
  }

  listOfErrors(): string[] {
    return Object.keys(this.control.errors).map(field => this.getMessage(field, this.control.errors[field], this.control));
  }

  getError(): string {
    const errors = Object.keys(this.control.errors).map(field => this.getMessage(field, this.control.errors[field], this.control));
    return errors[0];
  }

  private getMessage(type: string, params: any, control: any) {
    let fname = '';
    fname = this.getControlName(control);
    fname = fname.replace(new RegExp('_', 'g'), ' ');
    fname = fname.replace(' id', '').toLowerCase();
    fname = fname.replace(/\b\w/g, l => l.toUpperCase());
    if (ValidationErrorsComponent.errorMessages[type]) {
      // fname = this.translate.instant(fname);
      // const translatedText = this.translate.instant(type, {value: fname});
      // return translatedText;
      const msg = ValidationErrorsComponent.errorMessages[type](params);
      return msg.replace('##FIELD##', fname);
    }

    return '';
  }

  getControlName(c: AbstractControl): string | null {
    if (this.fieldName && this.fieldName !== '') {
      return this.fieldName;
    }
    const formGroup = c.parent.controls;
    return Object.keys(formGroup).find(name => c === formGroup[name]) || null;
  }

}
