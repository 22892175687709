import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'formatPhoneNumber'
})

export class FormatPhoneNumberPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value) {
      value = value.replace(/_/g, '');
      const tmp = value.split('x');
      if (!tmp[1]) {
        value = value.replace('x', '');
      }
      return value;
    } else {
      return value;
    }
  }
}
