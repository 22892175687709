import { Component, } from '@angular/core';

@Component({
  standalone: true,
  selector: 'app-loader',
  template: `
  <div class="panel__refresh medium-spinner active-spinner">
    <span class="spin-circle"></span>
  </div>`,
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {}
