import { CommonModule } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  standalone: true,
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  imports: [
    CommonModule
  ]
})
export class IconComponent implements OnInit {
  @Input() name: string;
  // Optional Inputs
  @Input() title      ?: string;
  @Input() size       ?: string;
  @Input() fixed      ?: boolean;
  @Input() animation  ?: string;
  @Input() rotate     ?: string | number;
  @Input() inverse    ?: boolean;

  private _optionalClasses: string[] = [];
  constructor() { }

  ngOnInit() {
    if (!this.name) {
      throw new Error('Missing "name" property for icon component');
    }

    if (this.name === 'edit') {
      this.name = 'pencil';
    } else if (this.name === 'delete') {
      this.name = 'trash-o';
    } else if (this.name === 'close') {
      this.name = 'times';
    }

    if (this.size) {
      this.addToOptionalClasses(`bi-${this.size}`);
    }

    if (this.fixed) {
      this.addToOptionalClasses('bi-fw');
    }

    if (this.animation) {
      this.addToOptionalClasses(`bo-${this.animation}`);
    }

    if (this.rotate) {
      const rotateClass = (typeof this.rotate === 'number') ? `bi-rotate-${this.rotate}` : `bi-flip-${this.rotate}`;
      this.addToOptionalClasses(rotateClass);
    }

    if (this.inverse) {
      this.addToOptionalClasses('fa-inverse');
    }

  }

  get optionalClasses() {
    return this._optionalClasses;
  }

  private addToOptionalClasses(addClass: string): void {
    this._optionalClasses.push(addClass);
  }
}
