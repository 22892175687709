// import { enableProdMode } from '@angular/core';
// import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

// import { AppModule } from './app/app.module';
// import { environment } from './environments/environment';

// if (environment.production) {
//   enableProdMode();
// }

// document.addEventListener('DOMContentLoaded', () => {
//   platformBrowserDynamic()
//     .bootstrapModule(AppModule)
//     .then(() => {
//       if ('serviceWorker' in navigator && environment.production) {
//         navigator.serviceWorker.register('/ngsw-worker.js');
//       }
//     })
//     .catch(err => console.log());
// });


import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { appConfigBase } from './app/app.config';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

document.addEventListener('DOMContentLoaded', () => {
  bootstrapApplication(AppComponent, appConfigBase)
    .then(() => {
      // Register service worker if in production mode
      if ('serviceWorker' in navigator && environment.production) {
        navigator.serviceWorker.register('/ngsw-worker.js');
      }
    })
    .catch(err => console.error(err));
});
