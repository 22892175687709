import { Routes } from '@angular/router';
import { LayoutComponent } from '@layout/components/layout.component';
import { AuthGuard } from './salesonepro-common';

export const routes: Routes = [

  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadComponent: () => import('./core/dashboard/dashboard.component').then(m => m.DashboardComponent),
        data: {
          breadcrumb: 'Dashboard',
        }
      },
      {
        path: 'orders',
        loadChildren: () => import('./core/orders/order.routes').then(m => m.OrderRoutes)
      },
      // {
      //   path: 'examples',
      //   loadChildren: () => import('./core/examples/example.module').then(m => m.ExampleModule),
      // },
      {
        path: 'account-details',
        loadComponent: () => import('./core/account-details/account-details.component').then(m => m.AccountDetailsComponent),
        data: {
          breadcrumb: 'Account Details',
        }
      }
    ]
  },
  {
    path: 'setpassword/:key',
    loadComponent: () => import('@core/setpassword/setpassword.component').then(m => m.SetpasswordComponent),
  },
  {
    path: 'login',
    loadComponent: () => import('@core/login/login.component').then(m => m.LoginComponent),
  },
  {
    path: '404',
    loadComponent: () => import('./layout/components/notfound/notfound.component').then(m => m.NotfoundComponent),
  },
  {
    path: '**',
    redirectTo: '404',
  },
];