import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  standalone: true,
  selector: 'app-server-validation-error',
  templateUrl: './server-validation-error.component.html',
  imports: [
    CommonModule
  ]
})
export class ServerValidationErrorComponent {
  @Input() errors: any;
  @Input() key: string | number;

  isArray(obj) {
    return Array.isArray(obj);
  }

}
