import { Component } from '@angular/core';
import { SidebarComponent } from './sidebar/sidebar.component';
import { RouterLink, RouterOutlet } from '@angular/router';
import { FooterComponent } from './footer/footer.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { HeaderComponent } from './header/header.component';

@Component({
    standalone: true,
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    imports:[
      SidebarComponent,
      RouterOutlet,
      FooterComponent,
      BreadcrumbsComponent,
      HeaderComponent
    ]
})
export class LayoutComponent {


  constructor() { }

}
